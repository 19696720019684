<template>
  <div>
    <CommonPop
    style="background-color: #fff; height: 100%; width: 100%; max-width: 100%; padding:0"
    overlayBgColor="#14151e"
    position="bottom"
    @close='closePop'
    v-model="show"
    @click-overlay="closePop"
    >
    <div class="gender-wrap">
      <div class="title">{{ $t('gender.title') }}</div>

      <div class="sex">

        <div class="man" :class="{'active': gender === 1 }" @click="changeGender(1)">
          <img src="@/assets/img/user/man.svg" />
          <p>{{ $t('gender.man') }}</p>
        </div>

        <div class="female" :class="{'active' : gender === 2 }" @click=changeGender(2)>
          <img src="@/assets/img/user/female.svg" />
          <p>{{ $t('gender.female') }}</p>
        </div>

      </div>

      <div class="btns">
        <VanButton class="btn" type="primary" round  @click="handleSelectGender">{{ $t('common.confirm') }}</VanButton>
      </div>

      <div class="button-line"></div>
    </div>
    
    </CommonPop>

    <GenderConfirmPop v-model="showConfirmPop" :gender="gender" @close="closeGenderConfirmPop" @submitGender="updateGender" />
    <FillContactPop v-model="showFillContactPop" @close="closeFillContactPop" @submitContact="updateContact" />
  </div>
  
</template>


<script>
import CommonPop from '../../components/CommonPop.vue';
import FillContactPop from './FillContactPop.vue';
import GenderConfirmPop from './GenderConfirmPop.vue';
import { updateSex, updateContact } from '@/service/user'
import {mapActions, mapState} from "vuex";

export default {
  name: 'SelectGender',
  components: {
    CommonPop,
    GenderConfirmPop,
    FillContactPop
},
  data () {
    return {
      show: true,
      loading: false,
      gender: 1, // 1: 男性 2: 女性
      showConfirmPop: false,
      showFillContactPop: false
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    closePop () {
      this.$emit('close')
    },
    changeGender(val) {
      if (val === this.gender) return
      this.gender = val
    },
    async handleSelectGender() {
      this.showConfirmPop = true
    },
    closeGenderConfirmPop() {
      this.showConfirmPop = false
    },
    closeFillContactPop() {
      this.showFillContactPop = false
    },
    async updateGender() {
      // 更新性别
      if (this.gender === 1) { // 男性
        const resp = await updateSex({ sex: this.gender })
        if (resp.code !== 200) return
        this.$toast('修改成功')
        this.userInfo.sex = this.gender
        this.showConfirmPop = false
        this.closePop()
        await this.changeUserInfo(this.userInfo)
        this.$gbUtils.handleToPage('/home')
      } else { // 女性
        this.showConfirmPop = false
        this.showFillContactPop = true
      }
      
    },
    async updateContact(contact) {
      // 更新联系方式
      const params = {
        wechat: contact.wechat,
        telegram: contact.telegram
      }
      const resp = await updateContact(params) // 上报联系方式以及修改性别为女性
      if (resp.code !== 200) return
      this.$toast('修改成功')
      this.userInfo.sex = this.gender
      this.userInfo.wechat = contact.wechat
      this.userInfo.telegram = contact.telegram
      this.showConfirmPop = false
      this.closePop()
      await this.changeUserInfo(this.userInfo)
      this.$gbUtils.handleToPage('/uploadVideo')
    }

  },
  mounted () {
    if (this.userInfo.sex !== 0) {
      this.$gbUtils.handleToPage('/home')
    }
  }
}
</script>

<style lang="scss" scoped>
.gender-wrap {
  width: 100%;
  height: 100%;
  padding: 50px 20px 20px 20px;
  background: #14151e;

  .title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    width: inherit;
    text-align: center;
    line-height: 28px;
  }

  .sex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-top: 100px;

      &>div {
        width: calc(50% - 20px);
        height: 190px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 80px;
          height: 80px;
        }
        p {
          color: #fff;
          font-size: 16px;
          margin-top: 25px;
        }
      }
      .man {
        p {
          color: #9984DB;
        }
      }
      .female {
        p {
          color: #FD2084;
        }
      }

      .active {
        background: #1F202D;
        border-radius: 10px;
        
      }
  }

  .btns {
    position: absolute;
    bottom: 100px;
    left: calc(50% - 100px);
    width: 200px;

    .btn {
      width: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #524774;
      border-color: #524774;
      height: 38px;
    }
  }
  .button-line {
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px/2);
    bottom: 8px;
    background: #FFFFFF;
    border-radius: 100px;
  }
  /deep/.van-button__text {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>