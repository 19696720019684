<template>
  <van-popup 
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)', 'backdrop-filter': 'blur(2px)' }"
    :style="{ width: '100%', maxHeight: '80%', minHeight: '232px', background: '#1E232D', padding: '0 10px', borderRadius: '10px 10px 0 0'}"  
    :transition-appear=true
    position="bottom" 
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @open="init"
    @close='closePop'
    round
  >

  <div class="box">
    <div class="box-title">{{ title }}</div>
    <div class="box-tip">
      <input v-model="wechat" type="text" placeholder="请输入微信号" />
      <input v-model="telegram" type="text" placeholder="请输入电报号" />
    </div>
    <div class="btns">
      <div class="cancel" @click="closePop">{{ $t('common.cancel') }}</div>
      <div class="sure" @click="handleSubmit">{{ $t('common.confirm') }}</div>
    </div>
  </div>
</van-popup>

</template>

<script>
export default {
  name:'',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    gender: {
      type: Number,
      default: 1
    }
  },
  computed: {
    title() {
      return '填写联系方式（至少填写一种）'
    }
  },
  data() {
    return {
      wechat: '',
      telegram: ''
    }
  },
  methods: {
    init() {
    },
    closePop() {
      this.$emit('input', false)
    },
    handleSubmit() {
      if (this.$gbUtils.isEmptyString(this.wechat) && this.$gbUtils.isEmptyString(this.telegram)) {
        this.$toast('请至少填写一种联系方式')
        return
      }
      
      this.$emit('submitContact', {
        wechat: this.wechat,
        telegram: this.telegram
      })
      this.wechat = ''
      this.telegram = ''
      this.closePop()
    }
  },
  mounted() {
    this.init()
  },
  created() {
  }
}
</script>


<style lang="scss" scoped>
  .box {
    padding: 20px 10px;
    .box-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .box-tip {
      font-size: 16px;
      line-height: 22px;
      input {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        background: #1E232D;
        border: 1px solid #3A3D4E;
        color: #fff;
        font-size: 14px;
        padding: 0 10px;
        margin-bottom: 10px;
      }
    }

    .btns {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      &>div {
        width: 47%;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
      }
      .cancel {
        background: #FD2084;
      }
      .sure {
        background: #8A73D0;
      }
    }

  }
</style>