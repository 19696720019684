<template>
  <van-popup 
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)', 'backdrop-filter': 'blur(2px)' }"
    :style="{ width: '100%', maxHeight: '80%', minHeight: '232px', background: '#1E232D', padding: '0 10px', borderRadius: '10px 10px 0 0'}"  
    :transition-appear=true
    position="bottom" 
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @open="init"
    @close='closePop'
    round
  >

  <div class="box">
    <div class="box-title">{{ title }}</div>
    <div class="box-tip">{{ $t('gender.tip') }}</div>
    <div class="btns">
      <div class="cancel" @click="closePop">{{ $t('common.cancel') }}</div>
      <div class="sure" @click="handleSubmit">{{ $t('common.confirm') }}</div>
    </div>
    <div class="botton-white-line"></div>
  </div>
</van-popup>

</template>

<script>
export default {
  name:'',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    gender: {
      type: Number,
      default: 1
    }
  },
  computed: {
    title() {
      if (this.gender === 1) return this.$t('gender.select') + this.$t('gender.man')
      else return this.$t('gender.select') + this.$t('gender.female')
    }
  },
  data() {
    return {
    }
  },
  methods: {
    init() {
    },
    closePop() {
      this.$emit('input', false)
    },
    handleSubmit() {
      this.closePop()
      this.$emit('submitGender')
    }
  },
  mounted() {
    this.init()
  },
  created() {
  }
}
</script>


<style lang="scss" scoped>
  .box {
    padding: 20px 10px;
    .box-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .box-tip {
      font-size: 16px;
      line-height: 22px;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      &>div {
        width: 47%;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
      }
      .cancel {
        background: #FD2084;
      }
      .sure {
        background: #8A73D0;
      }
    }

  }
</style>